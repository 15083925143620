import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import AutocompleteInput from 'web/components/primitives/autocomplete';
import Input from 'stories/input';
import parseTimeString from 'shared/lib/magic.time';

const handleTimeFocus = event => event.target.select();

export default function TimeInput({
  onChange,
  value,
  options,
  isOptional,
  useShortenedTimes,
  ...rest
}) {
  const [internalTime, setInternalTime] = useState(value);

  useEffect(() => {
    if (value !== internalTime) {
      setInternalTime(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const blurTime = ({ target: { value: inputValue } }) => {
    try {
      const time = parseTimeString(inputValue);
      const formattedTime = time
        ? moment(time).format(useShortenedTimes ? 'h:mm a' : 'hh:mm a')
        : inputValue;
      onChange(formattedTime);
    } catch (err) {
      if (!isOptional) {
        setInternalTime('invalid');
      } else {
        onChange(inputValue);
      }
    }
  };

  const Component = options ? AutocompleteInput : Input;
  const componentOnChange = options
    ? setInternalTime
    : ({ target }) => setInternalTime(target.value);
  return (
    <Component
      options={options}
      onChange={componentOnChange}
      onBlur={blurTime}
      onFocus={handleTimeFocus}
      value={internalTime}
      maxLength={50}
      {...rest}
    />
  );
}

TimeInput.propTypes = {
  isOptional: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

TimeInput.defaultProps = {
  options: undefined,
  isOptional: undefined,
  useShortenedTimes: false,
};
