"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.INSIGHT_EXPORT_FORMATS = exports.DEFAULT_ATTENDANCE_REASONS = exports.PRINT_SERVICES = exports.SHERPA_DOMAINS = exports.SSO_PROVIDERS_ENUM = exports.SSO_PROVIDERS = exports.SSO_SAML_PROVIDERS = exports.SSO_OAUTH_PROVIDERS = exports.CONTRACT_TYPES = exports.CARE_TYPES = exports.ORGANIZATION_TYPES = exports.ACCOUNT_STATUSES = exports.ACCOUNT_TYPES = void 0;
exports.ACCOUNT_TYPES = {
    test: {
        value: 'test',
        label: 'Test',
    },
    demo: {
        value: 'demo',
        label: 'Demo',
    },
    customer: {
        value: 'customer',
        label: 'Customer',
    },
};
exports.ACCOUNT_STATUSES = {
    new: {
        value: 'new',
        label: 'New',
        description: 'Newly provisioned account',
    },
    trial: {
        value: 'trial',
        label: 'Trial',
    },
    active: {
        value: 'active',
        label: 'Active',
        description: 'Paying account',
    },
    suspended: {
        value: 'suspended',
        label: 'Suspended',
        description: 'The account has not paid for X days and has been paused. Blocks user connections',
    },
    cancelled: {
        value: 'cancelled',
        label: 'Cancelled',
        description: 'The account has churned and thus no longer active. Users cannot login.',
    },
};
exports.ORGANIZATION_TYPES = {
    none: {
        value: 'none',
        label: 'None Selected',
    },
    corporation: {
        value: 'corporation',
        label: 'Corporation',
    },
    liabilityCompany: {
        value: 'liabilityCompany',
        label: 'Limited Liability Company',
    },
    partnership: {
        value: 'partnership',
        label: 'Partnership',
    },
    sole: {
        value: 'sole',
        label: 'Sole Proprietorship',
    },
    nonProfitCorporation: {
        value: 'nonProfitCorporation',
        label: 'Non-Profit Corporation',
    },
};
exports.CARE_TYPES = {
    adultDay: {
        value: 'adultDay',
        label: 'Adult Day',
    },
    adultWithDisabilities: {
        value: 'adultWithDisabilities',
        label: 'Adult with Disabilities',
    },
    al: {
        value: 'al',
        label: 'Assisted Living',
    },
    ccrc: {
        value: 'ccrc',
        label: 'Continuing Care Retirement Community',
    },
    il: {
        value: 'il',
        label: 'Independent Living',
    },
    memoryCare: {
        value: 'memoryCare',
        label: 'Memory Care',
    },
    snf: {
        value: 'snf',
        label: 'Skilled Nursing',
    },
    other: {
        value: 'other',
        label: 'Other',
    },
};
exports.CONTRACT_TYPES = {
    annual: {
        value: 'annual',
        label: 'Annual',
    },
    monthToMonth: {
        value: 'monthToMonth',
        label: 'Month-to-month',
    },
    trial: {
        value: 'trial',
        label: 'Trial',
    },
};
exports.SSO_OAUTH_PROVIDERS = [
    {
        value: 'google',
        label: 'Google',
    },
    {
        value: 'microsoft',
        label: 'Microsoft',
    },
    {
        value: 'apple',
        label: 'Apple',
    },
];
exports.SSO_SAML_PROVIDERS = [
    {
        value: 'ciscoDuo',
        label: 'Cisco Duo',
    },
];
exports.SSO_PROVIDERS = __spreadArray(__spreadArray([], exports.SSO_OAUTH_PROVIDERS, true), exports.SSO_SAML_PROVIDERS, true);
exports.SSO_PROVIDERS_ENUM = exports.SSO_PROVIDERS.map(function (provider) { return provider.value; });
var SHERPA_DOMAINS;
(function (SHERPA_DOMAINS) {
    SHERPA_DOMAINS["US"] = "US";
    SHERPA_DOMAINS["UK"] = "UK";
})(SHERPA_DOMAINS || (exports.SHERPA_DOMAINS = SHERPA_DOMAINS = {}));
var PRINT_SERVICES;
(function (PRINT_SERVICES) {
    PRINT_SERVICES["ACTIVITY_CONNECTIONS"] = "ACTIVITY_CONNECTIONS";
    PRINT_SERVICES["PORTER_ONE"] = "PORTER_ONE";
})(PRINT_SERVICES || (exports.PRINT_SERVICES = PRINT_SERVICES = {}));
exports.DEFAULT_ATTENDANCE_REASONS = [
    {
        key: 'meal-roomtray',
        type: 'MEAL',
        attendanceType: 'attended',
        name: 'Room Tray',
    },
    {
        key: 'meal-outofcommunity',
        type: 'MEAL',
        attendanceType: 'declined',
        name: 'Out of Community',
    },
    {
        key: 'activity-outofcommunity',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Out of Community',
    },
    {
        key: 'activity-hadvisitors',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Had Visitors',
    },
    {
        key: 'activity-sleepingornapping',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Sleeping or Napping',
    },
    {
        key: 'activity-hadappointment',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Had Appointment',
    },
    {
        key: 'activity-notinterested',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Not Interested',
    },
    {
        key: 'activity-notfeelingwell',
        type: 'ACTIVITY',
        attendanceType: 'declined',
        name: 'Not Feeling Well',
    },
];
var INSIGHT_EXPORT_FORMATS;
(function (INSIGHT_EXPORT_FORMATS) {
    INSIGHT_EXPORT_FORMATS["CSV"] = "csv";
    INSIGHT_EXPORT_FORMATS["JSON"] = "json";
})(INSIGHT_EXPORT_FORMATS || (exports.INSIGHT_EXPORT_FORMATS = INSIGHT_EXPORT_FORMATS = {}));
