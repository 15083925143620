import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactHintFactory from 'react-hint';
import { difference, includes, intersection, reduce, uniq } from 'lodash';

import { Icon, IconType } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';

import TableRow from './row';
import styles from './styles.scss';

const ReactHint = ReactHintFactory(React);

const Table = ({
  className,
  dataIdAccessor,
  hoverable,
  onRowClick,
  onSort,
  sort,
  data,
  columns,
  selected,
  onChangeSelected,
  stickyHeader,
  forwardedRef,
  children,
  ...props
}) => {
  const selectedById = useMemo(
    () => reduce(selected, (result, id) => ({ ...result, [id]: true }), {}),
    [selected],
  );

  const onHeaderClick = ({ accessor, nameSort, unsortable }) => () => {
    if (!sort || unsortable) {
      return;
    }

    let nameAccessor = accessor;
    if (nameSort && !sort.key) {
      nameAccessor = 'firstName';
    } else if (nameSort && sort.key === 'firstName' && sort.direction === -1) {
      nameAccessor = 'lastName';
    } else if (nameSort && sort.key === 'lastName' && sort.direction === -1) {
      nameAccessor = 'roomNumber';
    } else if (nameSort) {
      nameAccessor = sort.key;
    }

    let direction = 0;
    if (!sort.direction || nameAccessor !== sort.key) {
      direction = 1;
    } else if (sort.direction === 1) {
      direction = -1;
    }

    onSort({
      key: !direction ? undefined : nameAccessor,
      direction,
    });
  };

  // eslint-disable-next-line
  const renderHeader = ({ Header, nameSort, tooltip, headerClassName }) => {
    if (typeof Header !== 'string') {
      return <Header className={classNames({ [styles.hintable]: tooltip })} />;
    }
    let stringHeader = Header;
    if (nameSort && sort.key) {
      if (sort.key === 'firstName') {
        stringHeader = 'First Name';
      } else if (sort.key === 'lastName') {
        stringHeader = 'Last Name';
      } else if (sort.key === 'roomNumber') {
        stringHeader = 'Room Number';
      }
    }
    return (
      <span
        className={classNames(headerClassName, { [styles.hintable]: tooltip })}
      >
        {stringHeader}
      </span>
    );
  };

  // eslint-disable-next-line
  const renderSortIcon = ({ accessor, nameSort, unsortable }) => {
    const isAccessor =
      !unsortable &&
      sort &&
      (sort.key === accessor ||
        (nameSort &&
          (sort.key === 'firstName' ||
            sort.key === 'lastName' ||
            sort.key === 'roomNumber')));

    if (!isAccessor) {
      return null;
    }
    if (sort.direction < 0) {
      return (
        <Icon
          type={IconType.chevronDown}
          className={styles.sortIcon}
          size={16}
        />
      );
    }
    return (
      <Icon type={IconType.chevronUp} size={16} className={styles.sortIcon} />
    );
  };

  const renderHeaders = () =>
    columns.map(
      ({
        Header,
        columnClass,
        accessor,
        tooltip,
        justifyContent,
        unsortable,
        nameSort,
      }) => (
        <th
          key={accessor}
          onClick={onHeaderClick({ accessor, nameSort, unsortable })}
          className={classNames(styles.headerCell, columnClass, {
            [styles['headerCell--hoverable']]: sort && !unsortable,
            [styles['headerCell--underlined']]: sort && !tooltip && !unsortable,
            [styles['headerCell--sticky']]: stickyHeader,
          })}
        >
          <FlexContainer
            justifyContent={justifyContent || 'flexStart'}
            alignItems="center"
            data-rh-table={tooltip}
          >
            {renderHeader({ Header, nameSort, tooltip })}
            {renderSortIcon({ accessor, nameSort, unsortable })}
          </FlexContainer>
        </th>
      ),
    );

  const dataIds = data.map(row => row[dataIdAccessor]);

  const allSelected = useMemo(
    () => data.length && intersection(selected, dataIds).length === data.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, selected, dataIdAccessor],
  );

  const onToggle = useMemo(() => {
    if (!onChangeSelected) {
      return undefined;
    }
    return id => {
      let newSelected;
      if (includes(selected, id)) {
        newSelected = selected.filter(existing => existing !== id);
      } else {
        newSelected = [...selected, id];
      }
      onChangeSelected(newSelected);
    };
  }, [onChangeSelected, selected]);

  return (
    <div
      className={classNames(styles.container, className)}
      ref={forwardedRef}
      {...props}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            {!!onChangeSelected && (
              <th
                className={classNames(
                  styles.headerCell,
                  styles.checkbox,
                  styles['bodyCell--centered'],
                  { [styles['headerCell--sticky']]: stickyHeader },
                )}
              >
                <Icon
                  data-table-toggle-all
                  type={allSelected ? IconType.checkSquare : IconType.square}
                  onClick={() => {
                    onChangeSelected(
                      allSelected
                        ? difference(selected, dataIds)
                        : uniq([...selected, ...dataIds]),
                    );
                  }}
                  className={classNames(styles.checkbox, {
                    [styles['checkbox--active']]: allSelected,
                  })}
                />
              </th>
            )}
            {renderHeaders()}
          </tr>
        </thead>
        <tbody>
          {data.map(row => {
            const id = row[dataIdAccessor];
            return (
              <TableRow
                key={id}
                id={id}
                row={row}
                isSelected={selectedById[id]}
                onRowClick={onRowClick}
                hoverable={hoverable}
                onToggle={onToggle}
                columns={columns}
              />
            );
          })}
        </tbody>
      </table>
      {children}
      <ReactHint
        className={classNames(styles.hint, 'react-hint')}
        attribute="data-rh-table"
        position="bottom"
        autoPosition
        events
      />
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  dataIdAccessor: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hoverable: PropTypes.bool,
  onRowClick: PropTypes.func,
  onSort: PropTypes.func,
  sort: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.number,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      Header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
      Cell: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      columnClass: PropTypes.string,
      tooltip: PropTypes.string,
      justifyContent: PropTypes.string,
      unsortable: PropTypes.bool,
      nameSort: PropTypes.bool,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  onChangeSelected: PropTypes.func,
  stickyHeader: PropTypes.bool,
  children: PropTypes.node,
};

Table.defaultProps = {
  className: undefined,
  dataIdAccessor: 'key',
  hoverable: false,
  onRowClick: undefined,
  onSort: () => undefined,
  sort: undefined,
  selected: [],
  onChangeSelected: undefined,
  stickyHeader: false,
  children: undefined,
};

export default React.forwardRef((props, ref) => (
  <Table {...props} forwardedRef={ref} />
));
