import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Toastr from 'toastr';

import { FlexContainer, Icon, IconType, Text } from 'web/components/primitives';

import styles from './styles.scss';

const validateFileType = (accept, file) => {
  if (!accept) {
    return true;
  }
  const acceptedTypes = accept
    .split(',')
    .map(type => type.trim().toLowerCase());
  const fileType = file.name
    .split('.')
    .pop()
    .toLowerCase();
  return acceptedTypes.includes(`.${fileType}`);
};

const stopEvent = e => {
  e.preventDefault();
  e.stopPropagation();
};

const addDraggingClass = e => {
  stopEvent(e);
  e.target.classList.add(styles.dragover);
  e.target.classList.remove(styles.fileTypeError);
};

const removeDraggingClass = e => {
  stopEvent(e);
  e.target.classList.remove(styles.dragover);
  e.target.classList.remove(styles.fileTypeError);
};

export default function Dropzone({
  onChange,
  accept,
  children,
  multiple = true,
}) {
  const inputRef = useRef();
  const [isCSVUploading, setIsCSVUploading] = useState(false);

  const handleFileChange = files => {
    const validFiles = [];
    let csvFile = null;
    let hasCSV = false;

    for (const file of Array.from(files)) {
      const fileType = file.name
        .split('.')
        .pop()
        .toLowerCase();
      const isCSV = fileType === 'csv';

      if (isCSV) {
        if (isCSVUploading || hasCSV) {
          Toastr.error('You can only upload one CSV file at a time.', null, {
            positionClass: 'toast-top-center',
          });
          inputRef.current.value = '';
          return;
        }
        csvFile = file;
        hasCSV = true;
        setIsCSVUploading(true);
      } else if (validateFileType(accept, file)) {
        validFiles.push(file);
      } else {
        Toastr.error(
          `Failed to upload ${file.name}. Please check the file type and try again.`,
          null,
          { positionClass: 'toast-top-center' },
        );
      }
    }

    if (csvFile) {
      onChange(csvFile);
    } else if (validFiles.length > 0) {
      onChange(validFiles);
    }

    inputRef.current.value = '';
  };

  const content = children || (
    <FlexContainer className={styles.actionText} alignItems="center">
      <Icon type={IconType.paperclip} className={styles.icon} size={20} />
      <Text className={styles.linkText} mr={4}>
        Add file
      </Text>
      <Text>or drop file here</Text>
    </FlexContainer>
  );

  return (
    <div
      className={styles.ddContainer}
      onDrag={stopEvent}
      onDragStart={stopEvent}
      onDragEnd={removeDraggingClass}
      onDragOver={addDraggingClass}
      onDragEnter={addDraggingClass}
      onDragLeave={removeDraggingClass}
      onClick={() => inputRef.current.click()}
      onDrop={e => {
        removeDraggingClass(e);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
          handleFileChange(files);
        }
      }}
    >
      <FlexContainer
        className={styles.innerDDContainer}
        justifyContent="center"
      >
        <input
          type="file"
          accept={accept}
          className={styles.fileInput}
          onChange={e => {
            const files = e.target.files;
            if (files.length > 0) {
              handleFileChange(files);
            }
          }}
          ref={inputRef}
          multiple={multiple}
          data-qa-drop-input
        />
        {content}
      </FlexContainer>
    </div>
  );
}

Dropzone.propTypes = {
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  children: PropTypes.node,
};

Dropzone.defaultProps = {
  accept: '*',
  children: undefined,
};
